import React from 'react';
import loginSideImage from '../../../images/login_side.png';



import { getConfig } from '@edx/frontend-platform';
import { useIntl } from '@edx/frontend-platform/i18n';
import { Hyperlink, Image } from '@openedx/paragon';
import classNames from 'classnames';

import messages from './messages';

const LargeLayout = () => {
  const { formatMessage } = useIntl();

  return (
    <div className="w-50 d-flex">
      <div className="p-0 img_size">
        <img
          className="w-100 h-100 align-baseline"
          alt="Custom Login"
          src={loginSideImage}  // Use the imported image here
        />
      </div>
    </div>

  );
};

export default LargeLayout;
